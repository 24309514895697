import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form } from 'react-final-form';
import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, REQ_TYPE, ICON, DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { createWorkTimeBlockApiUrl, editWorkTimeBlockApiUrl } from 'projects/urls';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import { renderDuration, calculateDuration, formatDate } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { timeTrackerTaskOptionSpec } from 'projects/assets/js/lib/objectSpecs';

const StopWatchForm = ({ taskOptions, timeBlocks, onSuccess }) => {
  const dispatch = useDispatch();
  const activeOrg = useSelector(state => selectActiveOrg(state), null);
  const [currentWorkTimeBlock, setCurrentWorkTimeBlock] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment.utc());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.utc());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const { withoutTask, projects } = timeBlocks || {
      withoutTask: [],
      projects: [],
    };
    // Tries to find a work time block without endTime.
    // first check if it can be found on withoutTask table
    let currentTimeBlock = null;
    if (Array.isArray(withoutTask) && withoutTask.length > 0) {
      currentTimeBlock = withoutTask?.findLast(tb => !tb.endTime);
    }
    if (currentTimeBlock) {
      setCurrentWorkTimeBlock(currentTimeBlock);
      return;
    }

    if (Array.isArray(projects) && projects.length > 0) {
      // Otherwise, check if it exists inside projects[].workTimeBlocks
      for (let i = 0; i < projects.length; i += 1) {
        const found = projects[i]?.workTimeBlocks?.findLast(tb => !tb.endTime);
        if (found) {
          setCurrentWorkTimeBlock(found);
          break;
        }
      }
    }
  }, [timeBlocks]);

  const isStart = !currentWorkTimeBlock;

  const onFormSubmit = async (values) => {
    try {
      const { warning } = await dispatch(pushDataDS({
        pushApiUrl: isStart
          ? createWorkTimeBlockApiUrl(activeOrg.alias, true)
          : editWorkTimeBlockApiUrl(activeOrg.alias, currentWorkTimeBlock?.id, true),
        reqType: isStart ? REQ_TYPE.POST : REQ_TYPE.PUT,
        values: isStart ? { taskId: values.taskId } : { ...values },
      }));

      if (!isStart) {
        setCurrentWorkTimeBlock(null);
        if (warning) {
          toastr.warning('Time entry cancelled', warning);
        } else {
          toastr.success('Well Done!', 'Time entry submitted successfully.');
        }
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const propertyErrors = omit(error.errors || {}, '_error', '_meta');
      const propertyErrorsKeys = Object.keys(propertyErrors);
      let errorMessage = error._error || error.message;
      if (propertyErrorsKeys.length > 0) {
        errorMessage = propertyErrorsKeys.map(key => `${key}: ${propertyErrors[key]}`).join(';');
      }
      toastr.error('Oh Snap!', errorMessage);
    }
  };

  const calculateEarnings = ({ startTime, taskId }) => {
    const task = Array.isArray(taskOptions)
      && taskOptions.length > 0 && taskOptions.find(o => o.value === taskId);

    if (!task) {
      return <NumberTpl currency={activeOrg.currency} value={0} />;
    }

    const { currency, rateAmount } = task;

    const hours = calculateDuration(
      startTime,
      formatDate(currentTime, DATETIME_FORMAT_DEFAULT),
    ) / 3600;
    const total = (parseFloat(rateAmount) * hours).toFixed(2);

    return <NumberTpl currency={currency} value={total} />;
  };

  if (!timeBlocks) {
    return null;
  }

  return (
    <Form
      initialValues={{
        taskId: currentWorkTimeBlock?.task?.id,
        startTime: currentWorkTimeBlock?.startTime,
      }}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, values: { startTime, taskId } }) => (
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="row stop-watch-timer py-5 py-xl-0">
            <div className="col-12 col-xl-6 mb-4 mb-xl-0 d-flex align-items-center">
              <CustomSelectField
                name="taskId"
                className="mb-0 w-100"
                defaultOptionText={(
                  <>
                    <i className={`${ICON.TIMER} mr-2`} />
                    What are you working on?
                  </>
                )}
                options={taskOptions.map(({ label, value }) => ({ label, value }))}
                disabled={!!startTime}
                isSearchable
              />
            </div>

            <div className="col-12 col-xl-6 d-flex align-items-center mt-3 mt-xl-0 justify-content-end">
              <div className="d-flex align-items-center mt-1">
                <i className={`${ICON.MONEY_BILL} mr-3 ml-auto text-success font-smaller`} />
                <span className="font-weight-bolder">
                  {calculateEarnings({ startTime, currentTime, taskId })}
                </span>
              </div>

              <div className="stop-watch-timer__duration">
                {renderDuration(startTime, formatDate(currentTime, DATETIME_FORMAT_DEFAULT), '00:00:00')}
              </div>

              <TDButton
                onClick={handleSubmit}
                type="submit"
                className={`stop-watch-timer__toggle-button ${startTime ? 'pl-0' : ''}`}
                variant={startTime ? BS_STYLE.DANGER : BS_STYLE.SUCCESS}
              >
                <i className={startTime ? ICON.STOP : ICON.PLAY} />
              </TDButton>
            </div>
          </div>
        </form>
      )}
    />
  );
};

StopWatchForm.propTypes = {
  taskOptions: PropTypes.arrayOf(timeTrackerTaskOptionSpec).isRequired,
  timeBlocks: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default StopWatchForm;
